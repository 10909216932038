/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
@use '@angular/material' as mat;
@import './sanitize.css';
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.define-palette(mat.$indigo-palette);
$candy-app-accent:  mat.define-palette(mat.$blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include the theme styles for only specified components.
@include mat.core-theme($candy-app-theme);
@include mat.button-theme($candy-app-theme);
@include mat.checkbox-theme($candy-app-theme);
@include mat.radio-theme($candy-app-theme);
@include mat.form-field-theme($candy-app-theme);
@include mat.input-theme($candy-app-theme);


body {
    padding: 0;
    margin: 0;
}
@media screen and (max-width: 599px) {
    snack-bar-container{
        margin: 0 !important;
        vnc-notification{
            .vnc-notification{
                border-radius: 0px !important;
            }
        }
    }
  }
