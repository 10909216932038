
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

/* MaterialDesignIcons.com */

@import "~@mdi/font/scss/variables";
@import "~@mdi/font/scss/functions";
$mdi-font-path: "~@mdi/font/fonts";
@font-face {
    font-family: '#{$mdi-font-name}';
    src: url('#{$mdi-font-path}/#{$mdi-filename}-webfont.eot');
    src: url('#{$mdi-font-path}/#{$mdi-filename}-webfont.eot#iefix') format('embedded-opentype'),
    url('#{$mdi-font-path}/#{$mdi-filename}-webfont.woff2') format('woff2'),
    url('#{$mdi-font-path}/#{$mdi-filename}-webfont.woff') format('woff'),
    url('#{$mdi-font-path}/#{$mdi-filename}-webfont.ttf') format('truetype'),
    url('#{$mdi-font-path}/#{$mdi-filename}-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@import "~@mdi/font/scss/core";
@import "~@mdi/font/scss/icons";
@import "~@mdi/font/scss/extras";
@import "~@mdi/font/scss/animated";
.mdi {
    &.mdi-16px {
        :before {
            font-size: 16px;
        }
        line-height: 16px;
        height: 16px;
        width: 16px;
    }
    &.mdi-18px {
        line-height: 18px;
        height: 18px;
        width: 18px;
    }
    &.mdi-24px {
        line-height: 24px;
    }
    &.mdi-48px {
        line-height: 48px;
        height: 48px;
        width: 48px;
    }
}